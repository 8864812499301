<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      class="alert-modal"
      hide-header-close
      content-class="modal-register"
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="d-flex justify-content-center w-100 align-items-center">
          <div class="modal-title">ลงทะเบียน</div>
          <b-button
            variant="link"
            to="customer/new-customer"
            target="_blank"
            class="text-link"
          >
            สำหรับลูกค้า
          </b-button>
        </div>
      </template>
      <div class="d-block">
        <div v-if="isAddOptionalTelephone" class="register-box p-3">
          <b-row no-gutters>
            <b-col md="8" class="pr-3">
              <InputText
                type="number"
                textFloat=" "
                v-model="telList[0].tel"
                placeholder="เบอร์โทรศัพท์สำรอง"
                :isValidate="$v.telList.$each[0].tel.$error"
                :v="$v.telList.$each[0].tel"
              >
              </InputText>
            </b-col>
            <b-col md="3">
              <InputText
                type="number"
                textFloat=" "
                placeholder="เบอร์ต่อ"
                v-model="telList[0].ext"
                :disabled="!telList[0].tel"
              ></InputText>
            </b-col>
            <b-col
              md="1"
              class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
            >
              <font-awesome-icon
                icon="trash-alt"
                title="delete-btn"
                size="lg"
                class="pointer text-primary icon-delete"
                @click.stop="deleteTel(0)"
              />
            </b-col>
            <template v-for="(item, index) in telList" v-if="index != 0">
              <b-col md="8" class="pr-3">
                <InputText
                  type="number"
                  textFloat=" "
                  v-model="item.tel"
                  placeholder="เบอร์โทรศัพท์สำรอง"
                  :isValidate="$v.telList.$each[index].tel.$error"
                  :v="$v.telList.$each[index].tel"
                >
                </InputText>
              </b-col>
              <b-col md="3">
                <InputText
                  type="number"
                  textFloat=" "
                  placeholder="เบอร์ต่อ"
                  v-model="item.ext"
                  :disabled="!item.tel"
                ></InputText>
              </b-col>
              <b-col
                md="1"
                class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="delete-btn"
                  size="lg"
                  class="pointer text-primary icon-delete"
                  @click.stop="deleteTel(index)"
                />
              </b-col>
            </template>
          </b-row>

          <div class="d-flex justify-content-center">
            <b-button
              variant="primary-color"
              class="btn-modal btn-hover w-100 mt-3"
              @click="addOptinalTel"
              :disabled="
                isLoading ||
                (telList.length == limitOptionalTel && limitOptionalTel !== '')
              "
            >
              เพิ่มเบอร์โทรศัพท์สำรอง
              <!-- ( {{ telList.length }} /
              {{ limitOptionalTel }} ) -->
            </b-button>
          </div>
        </div>
        <RegisterFields
          v-else
          ref="registerFields"
          :form="form"
          @success="hide"
          :fields="fields"
          :dynamicField="dynamicField"
          @AddOptionalTelephone="AddOptionalTelephone"
        />
      </div>
      <template #modal-footer="{ hide }">
        <div
          class="d-flex justify-content-between w-100 my-0"
          v-if="isAddOptionalTelephone"
        >
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="closeOptionalPhone"
            :disabled="isLoading"
          >
            ย้อนกลับ
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="saveOptionalPhone"
            :disabled="isLoading"
          >
            บันทึก
          </b-button>
        </div>
        <div class="d-flex justify-content-between w-100 my-0" v-else>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import RegisterFields from "@/components/field/RegisterFields.vue";
import { minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    RegisterFields,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        prefix: null,
        subdistrict: null,
        district: null,
        province: null,
        email: "",
        country: null,
        custom_field: [],
        birthday: null,
        is_consent: 0,
      },
      now: null,
      fields: [],
      dynamicField: [],
      isAddOptionalTelephone: false,
      limitOptionalTel: null,
      telList: [],
    };
  },
  validations() {
    return {
      telList: {
        $each: {
          tel: {
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
        },
      },
    };
  },

  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.limitOptionalTel = this.$store.state.limit_secondary_telephone;
  },
  computed: {
    ...mapGetters({}),
  },

  watch: {
    telList: {
      handler(newVal) {
        newVal.forEach((item) => {
          if (!item.tel) {
            item.ext = "";
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async show() {
      this.form = {
        prefix: null,
        subdistrict: null,
        district: null,
        province: null,
        email: "",
        country: null,
        custom_field: [],
        gender: null,
        birthday: null,
        is_consent: 0,
      };
      this.isAddOptionalTelephone = false;
      (this.telList = []), this.telList.push({ tel: "", ext: "" });
      await this.$store.dispatch("setMainLoading", true);
      await this.getField();
      await this.$store.dispatch("setMainLoading", false);
      this.showModal = true;
    },
    async getField() {
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/registerform/1`
      );
      console.log(data.detail.result.fields.map((el) => el.name));
      if (data.result === 1) {
        this.fields = data.detail.result.fields.filter((el) => el.is_show == 1);

        this.dynamicField = data.detail.result.custom_field.filter(
          (el) => el.is_show == 1
        );

        for (const field of data.detail.result.custom_field) {
          if (field.field_type_id == 2) {
            field.user_answer = [];
          } else {
            field.user_answer = "";
          }
        }
      }
    },
    addOptinalTel() {
      if (
        !this.limitOptionalTel ||
        this.telList.length < this.limitOptionalTel
      ) {
        this.telList.push({ tel: "", ext: "" });
      }
    },
    hide() {
      (this.telList = []), (this.showModal = false);
    },
    AddOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalPhone() {
      this.telList = [];
      this.telList.push({ tel: "", ext: "" });
      this.$v.$reset();
      this.isAddOptionalTelephone = false;
    },
    saveOptionalPhone() {
      this.$v.telList.$touch();
      if (!this.$v.$error) {
        this.telList = this.telList.filter((item) => item.tel.trim() !== "");

        if (this.telList.length === 0) {
          this.telList.push({ tel: "", ext: "" });
        }
        this.form.optional_telephone = this.telList
          .map((item) => (item.ext ? `${item.tel}-${item.ext}` : item.tel))
          .join(",");
        this.isAddOptionalTelephone = false;
      }
    },
    deleteTel(index) {
      if (index == 0) {
        this.telList[0].tel = "";
        this.telList[0].ext = "";
        return;
      }
      this.telList.splice(index, 1);
    },
    async save() {
      this.$refs.registerFields.save();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .modal-register {
  .btn-link {
    border-color: transparent !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    padding: 0;
    color: #f7f7f7 !important;
  }

  .icon-delete {
    width: 20px;
    height: 20px;
  }
  .register-box {
    background: #f7f7f7;
    border: 1px solid #d8dbe0;

    max-height: 500px;
    overflow: auto;
  }
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 1px solid white !important;
      border-bottom: 3px solid white !important;
      font-weight: 600;
      background: white !important;
      color: #333 !important;
    }
    .nav-link.active {
      border: 1px solid var(--secondary-color) !important;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
.text-link {
  right: 0;
  position: absolute;
  margin-right: 20px;
}
</style>
